import { reactive, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import useMairies from '@/services/super-admin/mairieService'
import useUsers from './super-admin/userService'
import useRequestCertificates from './requestCertificate/requestCertificate'
import useAttachements from './agent/attachementService'

// import axiosClient from '@/helpers/axios'
// import useAlertNotification from '@/services/notification/useAlert'

export default function useStatistiques() {
  const { mairies, getListeMairies } = useMairies()
  const user = JSON.parse(store.state.authStore.user)
  const {
    requestCertificateSigned,
    requestCertificateAwaitingSignature,
    listRequestCertificatesByMairieByStatus,
    listRequestCertificatesByUserByStatus, listRequestCertificatesByMairieAwaitingSignature, requestCertificateEnCours, requestCertificateRejete, requestCertificateTraite, requestCertificateSoumis,
    requestCertificateEnCoursByMairie, requestCertificateRejeteByMairie, requestCertificateTraiteByMairie, requestCertificateSoumiseByMairie,
  } = useRequestCertificates()
  const {
    getUsersByRoleByMairie, getListeUsers, users, usersSignataires, usersAgentsVerificateurs, getUsersByRoleByMairieId, getUsersByRole, usersAdministrateur, usersAgentVerificateur, usersRoleUtilisateur, usersSignataire,
  } = useUsers()
  const {
    listAttachementsByMairieByStatus, attachementsEnvoye, attachementsValide, attachementsRejete,
  } = useAttachements()
  const loader = ref(false)
  const statistiqueProcess = ref(false)
  const statistiqueSuccess = ref(false)
  const statistiques = reactive({
    totalMairies: null,
    totalUsers: null,
    totalRequestCertificateAwaitingSignatureByMairie: null,
    totalAttachmentsValideByMairie: null,
    totalAttachmentsRejeteByMairie: null,
    totalAttachmentsEnvoyeByMairie: null,
    totalSignataire: null,
    totalAdministrateur: null,
    totalAgentVerificateur: null,
    totalUtilisateur: null,
    totalRequestCertificateByMairie: null,
    totalRequestCertificateSignedByMairie: null,
    totalRequestCertificateEnCoursByMairie: null,
    totalRequestCertificateRejeteByMairie: null,
    totalRequestCertificateTraiteByMairie: null,
    totalRequestCertificateSoumiseByMairie: null,
    totalRequestCertificateEnCoursByUser: null,
    totalRequestCertificateTraiteByUser: null,
    totalRequestCertificateRejeteByUser: null,
    totalRequestCertificateSoumisByUser: null,
    totalSignatairesByMairie: null,
    totalAgentsVerificateursByMairie: null,
    totalDemandes: null,
    totalDemandesSignees: null,
  })
  const statistiquesSuperAdmin = async () => {
    await getListeMairies()
    await getListeUsers()
    // eslint-disable-next-line no-prototype-builtins
    if (!router.currentRoute.params.hasOwnProperty('slug')) {
      await getUsersByRoleByMairieId('SIGNATAIRE', user.mairie_id)
      await getUsersByRoleByMairieId('AGENT VERIFICATEUR', user.mairie_id)
    } else {
      await getUsersByRoleByMairie('SIGNATAIRE', router.currentRoute.params.slug)
      await getUsersByRoleByMairie('AGENT VERIFICATEUR', router.currentRoute.params.slug)
    }
    await listRequestCertificatesByUserByStatus('En cours')
    await listRequestCertificatesByUserByStatus('Soumis')
    await listRequestCertificatesByUserByStatus('Traité')
    await listRequestCertificatesByUserByStatus('Rejeté')

    await listRequestCertificatesByMairieAwaitingSignature(1)
    await listRequestCertificatesByMairieAwaitingSignature(0)

    await listAttachementsByMairieByStatus('Envoyé')
    await listAttachementsByMairieByStatus('Validé')
    await listAttachementsByMairieByStatus('Rejeté')

    await getUsersByRole('ADMINISTRATEUR')
    await getUsersByRole('AGENT VERIFICATEUR')
    await getUsersByRole('UTILISATEUR')
    await getUsersByRole('SIGNATAIRE')

    await listRequestCertificatesByMairieByStatus(user.mairie_id, 'En cours')
    await listRequestCertificatesByMairieByStatus(user.mairie_id, 'Soumis')
    await listRequestCertificatesByMairieByStatus(user.mairie_id, 'Traité')
    await listRequestCertificatesByMairieByStatus(user.mairie_id, 'Rejeté')

    statistiques.totalRequestCertificateSignedByMairie = requestCertificateSigned.value.length
    statistiques.totalRequestCertificateAwaitingSignatureByMairie = requestCertificateAwaitingSignature.value.length

    statistiques.totalMairies = mairies.value.length

    statistiques.totalAdministrateur = usersAdministrateur.value.length
    statistiques.totalAgentVerificateur = usersAgentVerificateur.value.length
    statistiques.totalSignataire = usersSignataire.value.length
    statistiques.totalUtilisateur = usersRoleUtilisateur.value.length

    statistiques.totalAttachmentsEnvoyeByMairie = attachementsEnvoye.value.length
    statistiques.totalAttachmentsValideByMairie = attachementsValide.value.length
    statistiques.totalAttachmentsRejeteByMairie = attachementsRejete.value.length

    statistiques.totalUsers = users.value.length
    statistiques.totalSignatairesByMairie = usersSignataires.value.length
    statistiques.totalAgentsVerificateursByMairie = usersAgentsVerificateurs.value.length

    statistiques.totalRequestCertificateEnCoursByUser = requestCertificateEnCours.value.length
    statistiques.totalRequestCertificateTraiteByUser = requestCertificateTraite.value.length
    statistiques.totalRequestCertificateRejeteByUser = requestCertificateRejete.value.length
    statistiques.totalRequestCertificateSoumisByUser = requestCertificateSoumis.value.length

    statistiques.totalRequestCertificateSoumiseByMairie = requestCertificateSoumiseByMairie.value.length
    statistiques.totalRequestCertificateTraiteByMairie = requestCertificateTraiteByMairie.value.length
    statistiques.totalRequestCertificateRejeteByMairie = requestCertificateRejeteByMairie.value.length
    statistiques.totalRequestCertificateEnCoursByMairie = requestCertificateEnCoursByMairie.value.length
  }

  return {
    statistiqueProcess,
    loader,
    statistiques,
    statistiqueSuccess,
    statistiquesSuperAdmin,
  }
}
